import { DiscardCaseComponent } from './discard-case/discard-case.component';
import { ManualPaymentComponent } from './manual-payment/manual-payment.component';
import { AddDeliveryAddressComponent } from './add-delivery-address/add-delivery-address.component';
import { CloseCaseComponent } from './close-case/close-case.component';
import { MissedInstallmentReportComponent } from './missed-installment-report/missed-installment-report.component';
import { FullPaymentStatementComponent } from './full-payment-statement/full-payment-statement.component';
import { InstallmentComponent } from './installment/installment.component';
import { MissingDataGeneralComponent } from './missing-data-general/missing-data-general.component';
import { RealEstateExecutionComponent } from './real-estate-execution/real-estate-execution.component';
import { AttachmentDeadlineDialogComponent } from './attachment-deadline-dialog/attachment-deadline-dialog.component';
import { AttachmentNoDeadlineDialogComponent } from './attachment-no-deadline-dialog/attachment-no-deadline-dialog.component';
import { NoAttachmentNoDeadlineDialogComponent } from './no-attachment-no-deadline-dialog/no-attachment-no-deadline-dialog.component';
import { ExecuteEventDialogComponent } from './execute-event-dialog/execute-event-dialog.component';
import { GenerateAttorneyDataDialogComponent } from './generate-attorney-data-dialog/generate-attorney-data-dialog.component';
import { StartExecutionDialogComponent } from './start-execution-dialog/start-execution-dialog.component';

export const transitionIds = [

] as const;

export const dialogIds = [
  'DIALOG_4-ACTION-DISCARD',
  'DIALOG_8-PAYMENT-STAGE',
  'DIALOG_9-PAYMENT-STAGE-WHILE-AT-ATTORNEY',
  'DIALOG_23-MISSED_INSTALLMENT',
  'DIALOG_24-NEW_ADDRESS',
  'DIALOG_26-DEBTOR_FULL_PAYMENT_APPROVE',
  'DIALOG_27-SOFT_PP',
  'DIALOG_5-ACTION_CANCEL',
  'HIANYPOTLAS-GENERAL',
  'SUBMISSION_REGISTRATION_OF_RIGHT_OF_ENFORCEMENT',
  'ATTORNEY-ATTACHMENT_DEADLINE',
  'ATTORNEY-ATTACHMENT_NO_DEADLINE',
  'ATTORNEY-NO_ATTACHMENT_NO_DEADLINE',
  'GENERATE-ATTORNEY-DATA',
  'START-HARD_2',
] as const;

export const dialogComponents = {
  'DIALOG_4-ACTION-DISCARD': DiscardCaseComponent,
  'DIALOG_8-PAYMENT-STAGE': ManualPaymentComponent,
  'DIALOG_9-PAYMENT-STAGE-WHILE-AT-ATTORNEY': ManualPaymentComponent,
  'DIALOG_23-MISSED_INSTALLMENT': MissedInstallmentReportComponent,
  'DIALOG_24-NEW_ADDRESS': AddDeliveryAddressComponent,
  'DIALOG_26-DEBTOR_FULL_PAYMENT_APPROVE': FullPaymentStatementComponent,
  'DIALOG_27-SOFT_PP': InstallmentComponent,
  'DIALOG_5-ACTION_CANCEL': CloseCaseComponent,
  'HIANYPOTLAS-GENERAL': MissingDataGeneralComponent,
  'SUBMISSION_REGISTRATION_OF_RIGHT_OF_ENFORCEMENT': RealEstateExecutionComponent,
  'ATTORNEY-ATTACHMENT_DEADLINE': AttachmentDeadlineDialogComponent,
  'ATTORNEY-ATTACHMENT_NO_DEADLINE': AttachmentNoDeadlineDialogComponent,
  'ATTORNEY-NO_ATTACHMENT_NO_DEADLINE': NoAttachmentNoDeadlineDialogComponent,
  'GENERATE-ATTORNEY-DATA': GenerateAttorneyDataDialogComponent,
  // 'EXECUTE-DIALOGLESS-TRANSITION': ExecuteEventDialogComponent,
  'START-HARD_2': StartExecutionDialogComponent,
} as const;