import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CaseEventsComponent } from './case-events.component';

@NgModule({
  declarations: [
    CaseEventsComponent,
  ],
  imports: [
    CommonModule,
    PayeeIconModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CaseEventsComponent,
  ]
})
export class CaseEventsModule { }
