import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CaseDetailsComponent } from './case-details.component';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';

@NgModule({
  declarations: [
    CaseDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    PayeeIconModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
  ],
  exports: [
    CaseDetailsComponent,
  ]
})
export class CaseDetailsModule { }
